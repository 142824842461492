<template>
  <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      <v-layout class="px-4">
        <v-flex style="width: 90%">
          <p class="m-0 text-truncate">Select a {{ label }}</p>
        </v-flex>
        <v-flex style="width: 10%" class="text-right">
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button"
            depressed
            icon
            v-on:click="$emit('close', true)"
            color="red white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <div class="px-4 w-100">
        <label for="address-search" class="font-weight-600"
          >Which <span class="text-lowercase">{{ label }}</span> would you like
          to select this for?</label
        >
        <div class="d-flex mb-2">
          <v-text-field
            id="address-search"
            v-model="search"
            class="line-item-header-text px-0 v-slot-m-none"
            :placeholder="`Search ${label}...`"
            dense
            filled
            solo
            flat
            color="cyan"
            :loading="loading"
            :disabled="loading"
            style="max-width: 400px"
          />
          <v-btn
            v-on:click="getAddress()"
            :loading="loading"
            :disabled="loading"
            style="height: 32px !important"
            class="custom-bold-button ml-2"
            depressed
            color="cyan white--text"
            >Search</v-btn
          >
          <v-btn
            v-if="
              getPermission('property:create') &&
              label != 'Billing Location' &&
              vToCustomerType != 'supplier-list'
            "
            class="mx-2 mx-md-4 custom-bold-button white--text"
            v-on:click="createPropertyDialog = true"
            color="cyan"
          >
            <v-icon dark left>mdi-plus</v-icon>
          </v-btn>
        </div>
        <div
          class="custom-border-top"
          style="max-height: calc(100vh - 300px); overflow-y: scroll"
        >
          <div v-if="list.length">
            <div
              v-for="(row, index) in list"
              :key="`address-list-key-${index}`"
              class="cursor-pointer hover-grey d-flex align-center custom-border-bottom py-3 px-3"
              v-on:click="selectAddress(row)"
            >
              <div style="width: 10%" class="text-center">
                <v-icon large>mdi-home-map-marker</v-icon>
              </div>
              <div style="width: 90%">
                <p class="m-0 font-weight-500 font-size-16">
                  <Barcode :barcode="row.barcode" />
                  <template v-if="!row?.withcompany">
                    <v-chip
                      label
                      small
                      class="ml-1"
                      outlined
                      v-if="row.is_same == 1"
                      style="min-height: 24px; font-size: 13px"
                      color="orange"
                      >Site Location &amp; Billing</v-chip
                    >
                    <v-chip
                      v-else
                      label
                      small
                      class="ml-1"
                      :color="row.type == 1 ? 'cyan' : 'orange'"
                      outlined
                      style="min-height: 24px; font-size: 13px"
                    >
                      <template v-if="row.type == 1">Site Location</template>
                      <template v-else>Billing</template>
                    </v-chip>
                  </template>
                  <template v-if="row?.withcompany == 1">
                    <v-chip
                      label
                      small
                      class="ml-1"
                      color="red"
                      outlined
                      style="min-height: 24px; font-size: 13px"
                    >
                      A&G
                    </v-chip>
                  </template>
                  <template v-if="row?.withcompany == 1 && row?.primary == 1">
                    <v-chip
                      label
                      small
                      class="ml-1"
                      color="green"
                      outlined
                      style="min-height: 24px; font-size: 13px"
                    >
                      Primary
                    </v-chip>
                  </template>
                </p>
                <p class="m-0 font-weight-500 font-size-16">
                  <span v-if="row.property_name"
                    >{{ row.property_name }} - </span
                  >{{ row.property_address }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-center align-center py-3 font-weight-500 text-lowercase"
          >
            <img
              width="40px"
              :src="$assetURL('media/error/empty.png')"
              class="row-not-found-image mr-2"
            />
            Uhh... There are no {{ label }} at the moment.
          </div>
        </div>
        <!-- <div v-if="vPropertyId">
            <p class="mb-0 mt-4 font-weight-600 red--text">
              Note: if you change, then Service Location from Line Items will be
              removed.
            </p>
          </div> -->
      </div>
      <Dialog
        v-if="createPropertyDialog"
        :commonDialog="createPropertyDialog"
        :dialogWidth="1149"
      >
        <template v-slot:title>Create Site Location</template>
        <template v-slot:body>
          <v-form
            ref="createProperty"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="createProperty"
          >
            <DialogPropertyAddress
              :property-customer="vToCustomer"
              :isDialog="true"
              isCustomerFrom
              v-on:savePropertyAddress="updateProperty"
            >
            </DialogPropertyAddress>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="!formValid || formLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-on:click="createProperty"
            >Save
          </v-btn>
          <v-btn
            :disabled="formLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="createPropertyDialog = false"
            >Close
          </v-btn>
        </template>
      </Dialog>
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { QUERY, PUT, CLEAR_ERROR } from "@/core/services/store/request.module";
import DialogPropertyAddress from "@/view/pages/customer/create-or-update/Internal-Property-Address-To-customer.vue";

import {
  SET_TO_PROPERTY,
  SET_TO_BILLING,
  SET_TO_PAYLOAD_ADDRESS_FLAG,
} from "@/core/services/store/delivery.module";
import ObjectPath from "object-path";

export default {
  name: "select-address",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      createPropertyDialog: false,
      search: null,
      formValid: true,
      formLoading: false,
      list: [],
    };
  },
  watch: {
    dialog(param) {
      this.search = null;
      if (param) {
        this.getAddress();
      }
    },
  },
  components: {
    Dialog,
    DialogPropertyAddress,
    Barcode,
  },
  methods: {
    selectAddress(row) {
      if (this.type == "billing") {
        if (row?.withcompany == 1) {
          this.$store.dispatch(SET_TO_PAYLOAD_ADDRESS_FLAG, 1);
        } else {
          this.$store.dispatch(SET_TO_PAYLOAD_ADDRESS_FLAG, 0);
        }
        this.$store.dispatch(SET_TO_BILLING, row);
      }

      if (this.type == "property") {
        this.$store.dispatch(SET_TO_PROPERTY, row);
      }

      this.$emit("close", true);
      this.loading = false;
      this.search = null;
      this.list = [];
    },
    updateProperty(param) {
      this.propertyAddress = param;
    },
    createProperty() {
      const _this = this;
      if (!_this.$refs.createProperty.validate()) {
        return false;
      }
      let addressFormData = new Array();
      addressFormData.push(_this.propertyAddress);
      const formData = _this.lodash.pickBy(
        addressFormData,
        _this.lodash.identity
      );

      if (_this.lodash.isEmpty(formData) === false) {
        _this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});
        _this.$store
          .dispatch(PUT, {
            url: "address/" + _this.vToCustomerId,
            data: { address: formData },
          })
          .then(() => {
            /*  _this.searchCustomerProperty(); */
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
            this.createPropertyDialog = false;
            this.getAddress();
          });
      }
    },
    getAddress() {
      this.loading = true;
      this.list = [];
      this.$store
        .dispatch(QUERY, {
          url: `property-list/${this.vToCustomerId}`,
          data: {
            search: this.search,
            type: this.vToCustomerType,
            withCompany: this.companyToBillingFlag,
          },
        })
        .then((response) => {
          this.list = ObjectPath.get(response, "data", []);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters([
      "vToCustomerType",
      "vToCustomer",
      "vToCustomerId",
      "vToCustomerDlvType",
      "companyToBillingFlag",
    ]),
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
};
</script>
