<template>
  <v-row class="mx-0">
    <v-col :md="12" class="py-0">
      <v-row>
        <v-col :md="8">
          <v-row class="custom-grey-border">
            <v-col
              md="12"
              class="light-gray-background custom-border-bottom d-flex justify-space-between"
            >
              <div
                style="font-size: 19px"
                class="text-uppercase font-weight-700 color-custom-blue"
              >
                Delivery Date & Time
              </div>
              <div></div>
            </v-col>
            <v-col :md="4">
              <label class="ml-2" for="visit-one-off-start-date"
                >Start Date</label
              >
              <DatePicker
                :disabled="deliveryLoading"
                key="visit-one-off-start-date"
                id="visit-one-off-start-date"
                placeholder="Start Date"
                :value="deliverySchedule.start_date"
                v-on:change="
                  updateScheduleState('dbOneOffSchedule.start_date', $event);
                  updateScheduleState('dbOneOffSchedule.end_date', $event);
                "
              />
            </v-col>
            <v-col :md="4">
              <label class="ml-2" for="visit-one-off-start-time"
                >Start Time</label
              >
              <TimePicker
                :disabled="deliveryLoading"
                key="delivery-one-off-start-time"
                id="delivery-one-off-start-time"
                placeholder="Start Time"
                :value="deliverySchedule.start_time"
                v-on:change="
                  updateScheduleState('dbOneOffSchedule.start_time', $event)
                "
                type="start-time"
              />
            </v-col>
            <v-col :md="4">
              <label class="ml-2" for="delivery-one-off-end-time"
                >End Time</label
              >

              <TimePicker
                :disabled="deliveryLoading"
                key="delivery-one-off-end-time"
                id="delivery-one-off-end-time"
                placeholder="End Time"
                :min-time="deliverySchedule.start_time"
                :value="deliverySchedule.end_time"
                v-on:change="
                  updateScheduleState('dbOneOffSchedule.end_time', $event)
                "
                type="end-time"
              />
            </v-col>
            <v-col md="12">
              <p
                class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"
              >
                This Delivery will start on
                {{ formatDate(deliverySchedule.start_date) }}
                {{ deliverySchedule.start_time }} and finish on
                {{ formatDate(deliverySchedule.end_date) }}
                {{ deliverySchedule.end_time }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col :md="4">
          <ScheduleTeam :key="`job-one-off-schedule-team-${scheduleKey}`" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import ScheduleTeam from "@/view/pages/delivery/Delivery-Team.vue";
import DatePicker from "@/view/components/main-job/QDatePicker.vue";
import TimePicker from "@/view/components/main-job/TimePicker.vue";
import {
  UPDATE_DEFAULT_DATE,
  UPDATE_SCHEDULE_STATE,
  /* RESET_ENGINEER, */
} from "@/core/services/store/delivery.module";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "delivery-ctx-one-off-schedule",
  data() {
    return {
      todayDate: moment(new Date()).format("YYYY-MM-DD"),
      scheduleKey: Number(new Date()),
    };
  },
  methods: {
    formatDate(date) {
      if (moment(date).isValid()) {
        return moment(date).format("DD MMMM YYYY");
      }
    },
    updateScheduleState(key, value) {
      if (value) {
        this.$store.commit(UPDATE_SCHEDULE_STATE, { key, value });
        /* this.$store.commit(RESET_ENGINEER); */
        this.updateDefaultDate();
      }
    },
    updateDefaultDate() {
      this.$nextTick(() => {
        this.$store.dispatch(UPDATE_DEFAULT_DATE, "single-delivery");
      });
    },
  },
  mounted() {
    this.updateDefaultDate();
  },
  components: {
    ScheduleTeam,
    TimePicker,
    DatePicker,
  },
  computed: {
    ...mapGetters(["deliverySchedule", "deliveryLoading"]),
    /*  todayTime() {
        return moment(this.deliverySchedule.start_date).format("hh:mm A");
      }, */
    /* updatedDate() {
        return moment(this.deliverySchedule.start_time, "hh:mm A")
          .add(60, "minutes")
          .format("hh:mm A");
      }, */
  },
};
</script>
