<template>
  <v-row class="custom-grey-border" :class="{ 'ml-0': !vShowCalendar }">
    <v-col
      md="12"
      class="light-gray-background custom-border-bottom d-flex justify-space-between"
    >
      <div
        style="font-size: 19px"
        class="text-uppercase font-weight-700 color-custom-blue"
      >
        Drivers
      </div>
      <div>
        <v-menu
          v-if="availableDeliveryTeam?.length"
          bottom
          left
          offset-y
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              right
              small
              depressed
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2">mdi-account-group</v-icon>Assign
            </v-btn>
          </template>
          <v-list class="light-gray-background pb-0" min-width="340">
            <v-list-item-title
              class="text-uppercase font-weight-700 color-custom-blue font-size-16 p-2 pt-0"
            >
              Select Driver
            </v-list-item-title>

            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 50vh; position: relative"
            >
              <v-list-item
                class="white-background"
                v-for="(team, index) in availableDeliveryTeam"
                :key="index"
              >
                <v-checkbox
                  :input-value="assignedDeliveryTeam"
                  :placeholder="team.engineer_display_name"
                  :value="team.engineer_id"
                  hide-details
                  color="cyan"
                  class="mr-4 mt-0"
                  v-on:change="updateScheduleState('dbAssignedTeam', $event)"
                  multiple
                >
                  <template v-slot:label>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-img
                          :lazy-src="$defaultProfileImage"
                          :src="team?.engineer_file?.url"
                        />
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="font-size-16 font-weight-500 text-capitalize"
                          v-html="team.engineer_display_name"
                        />

                        <template v-if="team && team.is_driver == 1">
                          <v-chip
                            label
                            outlined
                            text-color=""
                            small
                            style="max-width: 48px !important"
                            color="red"
                          >
                            Driver</v-chip
                          >
                        </template>
                        <!--     <v-list-item-subtitle
                          class="font-size-16 font-weight-500"
                          v-if="team.engineer_designation"
                          v-html="team.engineer_designation"
                        /> -->
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-checkbox>
              </v-list-item>
            </perfect-scrollbar>
          </v-list>
        </v-menu>
        <v-btn
          v-else
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          right
          small
          v-on:click="getEngineers()"
          depressed
          :disabled="deliveryLoading"
        >
          <v-icon class="mr-2">mdi-account-group</v-icon>Get Available
        </v-btn>
      </div>
    </v-col>
    <v-col md="12">
      <v-row>
        <v-col
          v-if="assignedDeliveryTeamObject?.length > 0"
          md="12"
          class="pa-0"
        >
          <v-list-item
            v-for="(row, index) in assignedDeliveryTeamObject"
            :key="index"
            class="border-bottom rounded px-2 mb-2 grey lighten-4"
          >
            <v-list-item-avatar class="my-1">
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="row?.engineer_file?.url"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                class="font-size-14 font-weight-500 text-capitalize"
              >
                {{ row.engineer_display_name }}</v-list-item-title
              >

              <template v-if="row && row.is_driver == 1">
                <v-chip
                  label
                  outlined
                  text-color=""
                  small
                  style="max-width: 48px !important"
                  color="red"
                >
                  Driver</v-chip
                >
              </template>
              <!--   <v-list-item-subtitle
                class="font-size-12 font-weight-500"
                v-if="row.engineer_designation"
              >
                {{ row.engineer_designation }}</v-list-item-subtitle
              > -->
            </v-list-item-content>
            <v-list-item-icon class="my-1" link>
              <v-btn
                v-on:click="removeEngineer(row.engineer_id)"
                icon
                color="red lighten-1"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-col>
        <v-col v-else md="12">
          <p class="font-size-16">No drivers are currently assigned</p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import {
  REMOVE_ENGINEER,
  GET_AVAILABLE_ENGINEER,
  UPDATE_SCHEDULE_STATE,
  UPDATE_SCHEDULE_ENGINEER,
} from "@/core/services/store/delivery.module";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "job-ctx-schedule-team",
  data() {
    return {
      vShowCalendar: false,
      deliveryId: 0,
    };
  },
  methods: {
    updateScheduleState(key, value) {
      this.$store.commit(UPDATE_SCHEDULE_STATE, { key, value });
      this.$store.commit(UPDATE_SCHEDULE_ENGINEER);
    },
    removeEngineer(id) {
      this.$store.commit(REMOVE_ENGINEER, id);
    },
    getEngineers() {
      let schedule = new Object();
      schedule = this.deliverySchedule;

      this.$store.dispatch(GET_AVAILABLE_ENGINEER, {
        start_date: moment(schedule.start_date).format("YYYY-MM-DD"),
        start_time: moment(
          `${schedule.start_date} ${schedule.start_time}`
        ).format("HH:mm"),
        end_date: moment(schedule.end_date).format("YYYY-MM-DD"),
        end_time: moment(`${schedule.end_date} ${schedule.end_time}`).format(
          "HH:mm"
        ),
        force: /*  this.deliveryId && this.deliveryId > 0 ? 0 : */ 1,
      });
    },
  },
  beforeMount() {
    this.deliveryId = this.lodash.toSafeInteger(this.$route.params.id);
  },
  computed: {
    ...mapGetters([
      "deliverySchedule",
      "deliveryLoading",
      "availableDeliveryTeam",
      "assignedDeliveryTeam",
      "assignedDeliveryTeamObject",
    ]),
  },
};
</script>
